html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-family: "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Segoe UI", "Microsoft YaHei", "微软雅黑", sans-serif;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.main .header {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
}

.main .header .item-left {
    flex: 4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main .header .item-left .logo {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main .header .item-left .nav {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;
}

.main .header .item-left .nav a {
    margin-right: 25px;
    font-size: 14px;
    color: #2b2b2b;
}

.main .header .item-center {
    flex: 2;
    display: flex;
    justify-content: center;
    font-size: 24px;
}

.main .header .item-right {
    flex: 4;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.main .wrapper {
    flex: 1;
    display: flex;
    background: url(../img/cs-background3.png);
}

.main .wrapper .bg-mask {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.main .wrapper .register,
.main .wrapper .login {
    background: #fff;
    border: 1px solid #ddd;
    width: 485px;
    height: auto;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    padding: 15px 0;
}

.a-forget,
.a-register {
    color: #666;
}

.a-forget:hover,
.a-register:hover {
    color: #999;
}

.main .wrapper .register form h2,
.main .wrapper .login form h2 {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    color: #323a45
}

.main .wrapper .register form .input-text,
.main .wrapper .login form .input-text {
    height: 50px;
    border-radius: 5px;
}

.main .wrapper .register form .red-border,
.main .wrapper .login form .red-border {
    border-color: red;
}

.footer {
    position: relative;
    margin-top: -50px;
    display: flex;
    height: 50px;
    margin-left: 50px;
    font-size: 12px;

}

.footer a {
    margin-right: 25px;
    color: rgba(255, 255, 255, 0.5);
}

